// Modules Import
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { Select2Module } from 'ng2-select2';
import { AgmCoreModule } from '@agm/core';

// Components Import
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FourOhFourComponent } from './components/four-oh-four/four-oh-four.component';
import { BrowseDoorsComponent } from './components/browse-doors/browse-doors.component';
import { AddDoorsComponent } from './components/add-doors/add-doors.component';
import { EditDoorsComponent } from './components/edit-doors/edit-doors.component';
import { BrowseTombsComponent } from './components/browse-tombs/browse-tombs.component';
import { AddTombComponent } from './components/add-tomb/add-tomb.component';
import { EditTombComponent } from './components/edit-tomb/edit-tomb.component';
import { LyticsComponent } from './components/lytics/lytics.component';
import { DoorDetailsComponent } from './components/door-details/door-details.component';
import { TombDetailsComponent } from './components/tomb-details/tomb-details.component';
import { DataDumpComponent } from './components/data-dump/data-dump.component';
import { SearchDoorsComponent } from './components/search-doors/search-doors.component';
import { SearchTombsComponent } from './components/search-tombs/search-tombs.component';

// Services import
import { AuthService } from './services/auth.service';
import { DoorsService } from './services/doors.service';
import { TombsService } from './services/tombs.service';
import { ValidationService } from './services/validation.service';
import { SearchService } from './services/search.service';
import { LyticsService } from './services/lytics.service';
import { DataDumpService } from './services/data-dump.service';

// Guards & Other Import
import { environment } from '../environments/environment';
import { AuthGuard } from './guards/auth.guard';
import { DropZoneDirective } from './directives/drop-zone.directive';

// Routes
const appRoutes: Routes = [
  // Open Routes
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  // Restricted routes
  {path: '', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'browse-tombs', component: BrowseTombsComponent, canActivate: [AuthGuard]},
  {path: 'browse-doors', component: BrowseDoorsComponent, canActivate: [AuthGuard]},
  {path: 'add-tomb', component: AddTombComponent, canActivate: [AuthGuard]},
  {path: 'add-door', component: AddDoorsComponent, canActivate: [AuthGuard]},
  {path: 'edit-tomb/:id', component: EditTombComponent, canActivate: [AuthGuard]},
  {path: 'edit-door/:id', component: EditDoorsComponent, canActivate: [AuthGuard]},
  {path: 'details-tomb/:id', component: TombDetailsComponent, canActivate: [AuthGuard]},
  {path: 'details-door/:id', component: DoorDetailsComponent, canActivate: [AuthGuard]},
  {path: 'lytics', component: LyticsComponent, canActivate: [AuthGuard]},
  {path: 'search-tombs', component: SearchTombsComponent, canActivate: [AuthGuard]},
  {path: 'search-doors', component: SearchDoorsComponent, canActivate: [AuthGuard]},
  {path: 'data-dump', component: DataDumpComponent, canActivate: [AuthGuard]},
  // 404 route
  {path: '**', component: FourOhFourComponent}
];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NavbarComponent,
    FooterComponent,
    LogoutComponent,
    FourOhFourComponent,
    BrowseDoorsComponent,
    AddDoorsComponent,
    EditDoorsComponent,
    BrowseTombsComponent,
    AddTombComponent,
    EditTombComponent,
    LyticsComponent,
    DoorDetailsComponent,
    TombDetailsComponent,
    DataDumpComponent,
    SearchDoorsComponent,
    SearchTombsComponent,
    DropZoneDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    RouterModule.forRoot(appRoutes),
    FlashMessagesModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    Select2Module,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCR4lPUGkFVuPYTe6n7Do6L01txiEzdEuA'
    })
  ],
  exports: [RouterModule],
  providers: [
    AuthService,
    DoorsService,
    TombsService,
    ValidationService,
    SearchService,
    LyticsService,
    DataDumpService,
    AuthGuard
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})

export class AppModule { }

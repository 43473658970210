import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';

import { DataDumpService } from '../../services/data-dump.service';

@Component({
  selector: 'app-data-dump',
  templateUrl: './data-dump.component.html',
  styleUrls: ['./data-dump.component.css']
})
export class DataDumpComponent implements OnInit {

  link: Boolean = false;

  constructor(
    private dataDump: DataDumpService,
    private flashMessages: FlashMessagesService
  ) { }

  ngOnInit() {
    this.dataDump.dumpTombs();
  }

  resCheck(data) {
    if (data === 'File created') {
      this.link = true;
      this.flashMessages.show('Plik utworzony', {
        cssClass: 'alert-success',
        timeout: 5000
      });
      window.open('out.xlsx');
    } else {
      this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {
        cssClass: 'alert-danger',
        timeout: 5000
      });
    }
  }

  startInfo() {
    this.flashMessages.show('Generowanie pliku.', {
      cssClass: 'alert-info',
      timeout: 5000
    });
  }

  tombsDump() {
    this.link  = false;
    this.startInfo();
    this.dataDump.dumpTombs()
      .subscribe(data => this.resCheck(data));
  }

  doorsDump() {
    this.link  = false;
    this.startInfo();
    this.dataDump.dumpDoors()
      .subscribe(data => this.resCheck(data));
  }

}

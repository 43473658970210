import { Component, OnInit } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Select2OptionData } from 'ng2-select2';
import { FlashMessagesService } from 'angular2-flash-messages';

import { TombsService } from '../../services/tombs.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-edit-tomb',
  templateUrl: './edit-tomb.component.html',
  styleUrls: ['./edit-tomb.component.css']
})
export class EditTombComponent implements OnInit {
  id: number;
  construction_t: Array<Select2OptionData> = [];
  context_t: Array<Select2OptionData> = [];
  equipment_t: Array<Select2OptionData> = [];
  ethnos_t: Array<Select2OptionData> = [];
  fasade_t: Array<Select2OptionData> = [];
  furnishing_t: Array<Select2OptionData> = [];
  inscription_t: Array<Select2OptionData> = [];
  int_dec_t: Array<Select2OptionData> = [];
  l_form: Array<Select2OptionData> = [];
  religion_t: Array<Select2OptionData> = [];
  rite_t: Array<Select2OptionData> = [];
  t_form: Array<Select2OptionData> = [];
  value: string[];
  current: any = {};
  record: any = {};
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: any;
  isHovering: boolean;
  selectorVal: any = {};
  hiden: Boolean = false;
  isDisabledBack: Boolean = false;
  isDisabledForward: Boolean = false;
  ls: any;

  constructor(
    private flashMessages: FlashMessagesService,
    private router: Router,
    private route: ActivatedRoute,
    private tombsService: TombsService,
    private storage: AngularFireStorage,
    private validation: ValidationService,
  ) {  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getDetails(this.id);
      if (localStorage.getItem('tomb')) {
        this.ls = JSON.parse(localStorage.getItem('tomb'));
      } else {
        this.ls = JSON.parse(localStorage.getItem('currentList'));
      }
      this.setDisabled();
      this.flashMessages.show('Loading...', {
        cssClass: 'alert-info',
        timeout: 1000
      });
    });
  }

  setDisabled() {
    if (this.ls.indexOf(this.id) === 0) {
      this.isDisabledBack = true;
    } else {
      this.isDisabledBack = false;
    }
    if (this.ls.indexOf(this.id) === this.ls.length - 1) {
      this.isDisabledForward = true;
    } else {
      this.isDisabledForward = false;
    }
  }

  onGoBack() {
    this.router.navigate(['/edit-tomb', this.ls[this.ls.indexOf(this.id) - 1]]);
  }
  onGoForward() {
    this.router.navigate(['/edit-tomb', this.ls[this.ls.indexOf(this.id) + 1]]);
  }

  getDetails(id) {
    this.tombsService
      .getTombDetails(id)
      .pipe(
        finalize(() => {
          this.downloadURL = this.record.pic
            ? this.storage.ref(this.record.pic).getDownloadURL()
            : '';
        })
      )
      .subscribe(async data => {
        this.record = data;
        await this.getSelectors();
      });
  }

  selectSelectors(data) {
    const dataKeys = [
      'construction',
      'context',
      'equipemnt',
      'ethnos',
      'fasadeTypeIdfasadeType',
      'tForm',
      'furnishing',
      'intDec',
      'inscription',
      'locus',
      'religion',
      'rite'
    ];
    const inputArr = [
      'construction_t',
      'context_t',
      'equipment_t',
      'ethnos_t',
      'fasade_t',
      't_form',
      'furnishing_t',
      'int_dec_t',
      'inscription_t',
      'l_form',
      'religion_t',
      'rite_t'
    ];
    inputArr.forEach((key, index) => {
      const input = JSON.parse(this.record[dataKeys[index]]);
      this.record[dataKeys[index]] =
        typeof input === 'object'
          ? input.filter(item =>
              typeof item === 'object' ? item[0] !== 'not set' : item !== 0
            )
          : input;
      this.record[dataKeys[index]] = Array.isArray(this.record[dataKeys[index]])
        ? this.record[dataKeys[index]]
        : [this.record[dataKeys[index]]];

      if (this.record[dataKeys[index]].length < 1) {
        this.record[dataKeys[index]].push(0);
      }
      this.selectorVal[key] = this[key]
        .filter((item, i) => this.record[dataKeys[index]].includes(item.id))
        .map(item => String(item.id));
    });
  }

  getSelectors() {
    this.tombsService.getSelectors().subscribe(async data => {
      await this.processSelectors(data);
      await this.selectSelectors(data);
    });
  }

  processSelectors(data) {
    data.construction_t.forEach(item => {
      const tmp = { id: item.idconstructionType, text: item.constructionT };
      this.construction_t.push(tmp);
    });
    data.context_t.forEach(item => {
      const tmp = { id: item.idcontextType, text: item.contextT };
      this.context_t.push(tmp);
    });
    data.equipment_t.forEach(item => {
      const tmp = { id: item.idequipmentType, text: item.equipmentT };
      this.equipment_t.push(tmp);
    });
    data.ethnos_t.forEach(item => {
      const tmp = { id: item.idethnosList, text: item.ethnosT };
      this.ethnos_t.push(tmp);
    });
    data.fasade_t.forEach(item => {
      const tmp = { id: item.idfasadeType, text: item.fasadeT };
      this.fasade_t.push(tmp);
    });
    data.furnishing_t.forEach(item => {
      const tmp = { id: item.idfurnishingType, text: item.furnishingT };
      this.furnishing_t.push(tmp);
    });
    data.inscription_t.forEach(item => {
      const tmp = { id: item.idinscriptionType, text: item.inscriptionT };
      this.inscription_t.push(tmp);
    });
    data.int_dec_t.forEach(item => {
      const tmp = { id: item.idintDecType, text: item.intDecT };
      this.int_dec_t.push(tmp);
    });
    data.l_form.forEach(item => {
      const tmp = { id: item.idlocusForm, text: item.lForm };
      this.l_form.push(tmp);
    });
    data.religion_t.forEach(item => {
      const tmp = { id: item.idreligionsList, text: item.religionT };
      this.religion_t.push(tmp);
    });
    data.rite_t.forEach(item => {
      const tmp = { id: item.idriteList, text: item.riteT };
      this.rite_t.push(tmp);
    });
    data.t_form.forEach(item => {
      const tmp = { id: item.idformsList, text: item.tForm };
      this.t_form.push(tmp);
    });

    this.hiden = true;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  startUpload(event: FileList) {
    const file = event.item(0);

    if (file.type.split('/')[0] !== 'image') {
      this.flashMessages.show('unsupported file type :( ', {
        cssClass: 'alert-danger',
        timeout: 5000
      });
      return;
    }

    const path = `tombs/${new Date().getTime()}_${file.name}`;

    this.record.pic = path;

    this.task = this.storage.upload(path, file);

    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();

    this.snapshot
      .pipe(
        finalize(
          () => (this.downloadURL = this.storage.ref(path).getDownloadURL())
        )
      )
      .subscribe();
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  changed(data: { value: string[] }, key) {
    this.current[key] =
      typeof data.value === 'object' ? data.value.join(', ') : data.value;
  }

  onSubmitClick() {
    const prepareArrays = async current => {
      await Object.keys(current).forEach(key => this.record[key] = current[key].split(', '));
    };

    prepareArrays(this.current);

    const newTomb = {
      invNo: this.record.id,
      catNo: this.record.catNo,
      tombName: this.record.tombName,
      siteName: this.record.siteName,
      country: this.record.country,
      wgsLat: this.record.wgsLat,
      wgsLon: this.record.wgsLon,
      nigLat: this.record.nigLat,
      nigLon: this.record.nigLon,
      oigLat: this.record.oigLat,
      oigLon: this.record.oigLon,
      remarks: this.record.remarks,
      pic: this.record.pic,
      capacity: this.record.capacity,
      intermQ: this.record.intermQ,
      chambersQ: this.record.chambersQ,
      dating: this.record.dating,
      tpq: this.record.tpq,
      taq: this.record.taq,
      tombReligion: this.record.religion_t
        ? this.record.religion_t
        : this.selectorVal.religion_t,
      tombEthnos: this.record.ethnos_t
        ? this.record.ethnos_t
        : this.selectorVal.ethnos_t,
      tombRite: this.record.rite_t
        ? this.record.rite_t
        : this.selectorVal.rite_t,
      tombIntDec: this.record.int_dec_t
        ? this.record.int_dec_t
        : this.selectorVal.int_dec_t,
      tombInscription: this.record.inscription_t
        ? this.record.inscription_t
        : this.selectorVal.inscription_t,
      tombFurnishing: this.record.furnishing_t
        ? this.record.furnishing_t
        : this.selectorVal.furnishing_t,
      tombContext: this.record.context_t
        ? this.record.context_t
        : this.selectorVal.context_t,
      tombEquipment: this.record.equipment_t
        ? this.record.equipment_t
        : this.selectorVal.equipment_t,
      tombConstruction: this.record.construction_t
        ? this.record.construction_t
        : this.selectorVal.construction_t,
      tombFasade: this.record.fasade_t
        ? this.record.fasade_t
        : this.selectorVal.fasade_t,
      tombLocus: this.record.l_form
        ? this.record.l_form
        : this.selectorVal.l_form,
      tombForm: this.record.t_form
        ? this.record.t_form
        : this.selectorVal.t_form,
      tombBib: this.record.ref
    };

    const dataSantize = data => data = Object.keys(data).map(item => data[item] ? data[item] : []);

    dataSantize(newTomb);

    if (!this.validation.tombValidator(newTomb)) {
      this.flashMessages.show('Fill out all the required fields!', {
        cssClass: 'alert-danger',
        timeout: 1000
      });
      return false;
    }

    this.tombsService.editTomb(newTomb, this.id).subscribe(data => {
      if (data.name) {
        this.flashMessages.show(
          `Adding to database filed: ${
            data.original.sqlMessage
          }, \n check your data and try again`,
          {
            cssClass: 'alert-danger',
            timeout: 3000
          }
        );
      } else {
        this.flashMessages.show('Success!', {
          cssClass: 'alert-success',
          timeout: 1000
        });
        this.current = {};
        this.record = {};
        localStorage.removeItem('browse-tombs');
        this.router.navigate(['/browse-tombs']);
      }
    });
  }
}

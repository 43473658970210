import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';

import { TombsService } from '../../services/tombs.service';
import { DoorsService } from '../../services/doors.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  tombs: Array<any>;
  doors: Array<any>;

  constructor(
    private flashMessages: FlashMessagesService,
    private doorsService: DoorsService,
    private tombsService: TombsService
  ) {}

  ngOnInit() {
    this.getList();
  }

  setLocalArr(arr, type, idString) {
    const ids = arr.map(record => String(record[idString])).reverse();
    localStorage.setItem(type, JSON.stringify(ids));
  }

  getList() {
    this.doorsService.getShortList().subscribe(data => {
      this.doors = data;
      this.setLocalArr(data, 'door', 'iddoorsMain');
    });
    this.tombsService.getShortList().subscribe(data => {
      this.tombs = data;
      this.tombs.forEach(item => item.tForm = item.tForm.split(', ').filter(el => el !== 'not set'));
      this.setLocalArr(data, 'tomb', 'id');
    });
  }

  onDeleteClick(id, type) {
    if (type === 'tombs') {
      this.tombsService.deleteTomb(id).subscribe(data => {
        if (data === 1) {
          this.flashMessages.show('Usunięto poprawnie', {
            cssClass: 'alert-success',
            timeout: 5000
          });
          this.getList();
        } else {
          this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {
            cssClass: 'alert-danger',
            timeout: 5000
          });
        }
      });
    } else {
      this.doorsService.deleteDoor(id).subscribe(data => {
        if (data === 1) {
          this.flashMessages.show('Usunięto poprawnie', {
            cssClass: 'alert-success',
            timeout: 5000
          });
          this.getList();
        } else {
          this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {
            cssClass: 'alert-danger',
            timeout: 5000
          });
        }
      });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';

import { TombsService } from '../../services/tombs.service';

@Component({
  selector: 'app-browse-tombs',
  templateUrl: './browse-tombs.component.html',
  styleUrls: ['./browse-tombs.component.css']
})
export class BrowseTombsComponent implements OnInit {
  records: Array<any>;

  constructor(
    private tombsService: TombsService,
    private flashMessages: FlashMessagesService
  ) {}

  ngOnInit() {
    if (localStorage.getItem('browse-tombs')) {
      this. records = JSON.parse(localStorage.getItem('browse-tombs'));
    } else {
      this.getList();
    }
  }

  getList() {
    this.tombsService.getTombsList().subscribe(data => {
      this.records = data;
      this.records.forEach(item => item.tForm = item.tForm.split(', ').filter(el => el !== 'not set'));
      this.setLocalArr();
      localStorage.setItem('browse-tombs', JSON.stringify(this.records));
    });
  }

  setLocalArr() {
    localStorage.removeItem('tomb');
    localStorage.removeItem('door');
    const ids = this.records.map(record => String(record.id));
    localStorage.setItem('currentList', JSON.stringify(ids));
  }

  onDeleteClick(id) {
    this.tombsService.deleteTomb(id).subscribe(data => {
      if (data === 1) {
        this.flashMessages.show('Usunięto poprawnie', {
          cssClass: 'alert-success',
          timeout: 5000
        });
        this.getList();
      } else {
        this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

import { DoorsService } from '../../services/doors.service';

@Component({
  selector: 'app-door-details',
  templateUrl: './door-details.component.html',
  styleUrls: ['./door-details.component.css']
})
export class DoorDetailsComponent implements OnInit {

  id: any;
  record: any;
  path: any;
  isDisabledBack: Boolean = false;
  isDisabledForward: Boolean = false;
  ls: any;

  constructor(
    private flashMessages: FlashMessagesService,
    private router: Router,
    private route: ActivatedRoute,
    private doorsService: DoorsService,
    private storage: AngularFireStorage,
    private location: Location
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getDetails(this.id);
      if (localStorage.getItem('door')) {
        this.ls = JSON.parse(localStorage.getItem('door'));
      } else {
        this.ls = JSON.parse(localStorage.getItem('currentList'));
      }
      this.setDisabled();
      this.flashMessages.show('Loading...', {
        cssClass: 'alert-info',
        timeout: 1000
      });
    });

  }

  setDisabled() {
    if (this.ls.indexOf(this.id) === 0) {
      this.isDisabledBack = true;
    } else {
      this.isDisabledBack = false;
    }
    if (this.ls.indexOf(this.id) === this.ls.length - 1) {
      this.isDisabledForward = true;
    } else {
      this.isDisabledForward = false;
    }
  }

  onGoBack() {
    this.router.navigate(['/details-door', this.ls[this.ls.indexOf(this.id) - 1]]);
  }
  onGoForward() {
    this.router.navigate(['/details-door', this.ls[this.ls.indexOf(this.id) + 1]]);
  }

  getDetails(id) {
    this.doorsService.getDoorDetails(id)
      .pipe(finalize(() => {
        this.path = this.record.pic ? this.storage.ref(this.record.pic).getDownloadURL() : '';
      }))
      .subscribe(data => {
        this.record = data;
      });
  }

  onDeleteClick(id) {
    this.doorsService.deleteDoor(id)
      .subscribe(data => {
        if (data === 1) {
          this.flashMessages.show('Usunięto poprawnie', {cssClass: 'alert-success', timeout: 5000});
          this.router.navigate(['/browse-doors']);
        } else {
          this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {cssClass: 'alert-danger', timeout: 5000});
        }
      });
  }

  goBack() {
    this.location.back();
  }
}

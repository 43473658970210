import { Component, OnInit } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/storage';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Select2OptionData } from 'ng2-select2';
import { FlashMessagesService } from 'angular2-flash-messages';

import { TombsService } from '../../services/tombs.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-add-tomb',
  templateUrl: './add-tomb.component.html',
  styleUrls: ['./add-tomb.component.css']
})
export class AddTombComponent implements OnInit {
  construction_t: Array<Select2OptionData> = [];
  context_t: Array<Select2OptionData> = [];
  equipment_t: Array<Select2OptionData> = [];
  ethnos_t: Array<Select2OptionData> = [];
  fasade_t: Array<Select2OptionData> = [];
  furnishing_t: Array<Select2OptionData> = [];
  inscription_t: Array<Select2OptionData> = [];
  int_dec_t: Array<Select2OptionData> = [];
  l_form: Array<Select2OptionData> = [];
  religion_t: Array<Select2OptionData> = [];
  rite_t: Array<Select2OptionData> = [];
  t_form: Array<Select2OptionData> = [];
  value: string[];
  current: any = {};
  newRecord: any = {};
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: any;
  isHovering: boolean;
  hiden: Boolean = false;

  constructor(
    private flashMessages: FlashMessagesService,
    private tombsService: TombsService,
    private storage: AngularFireStorage,
    private validation: ValidationService
  ) {}

  ngOnInit() {
    this.getSelectors();
    this.getID();
  }

  getSelectors() {
    this.tombsService
      .getSelectors()
      .subscribe(async data => await this.processSelectors(data));
  }

  getID() {
    this.tombsService.getID().subscribe(data => (this.newRecord.id = data));
  }

  processSelectors(data) {
    data.construction_t.forEach((item, index) => {
      const tmp = { id: item.idconstructionType, text: item.constructionT };
      this.construction_t.push(tmp);
    });
    data.context_t.forEach((item, index) => {
      const tmp = { id: item.idcontextType, text: item.contextT };
      this.context_t.push(tmp);
    });
    data.equipment_t.forEach((item, index) => {
      const tmp = { id: item.idequipmentType, text: item.equipmentT };
      this.equipment_t.push(tmp);
    });
    data.ethnos_t.forEach((item, index) => {
      const tmp = { id: item.idethnosList, text: item.ethnosT };
      this.ethnos_t.push(tmp);
    });
    data.fasade_t.forEach((item, index) => {
      const tmp = { id: item.idfasadeType, text: item.fasadeT };
      this.fasade_t.push(tmp);
    });
    data.furnishing_t.forEach((item, index) => {
      const tmp = { id: item.idfurnishingType, text: item.furnishingT };
      this.furnishing_t.push(tmp);
    });
    data.inscription_t.forEach((item, index) => {
      const tmp = { id: item.idinscriptionType, text: item.inscriptionT };
      this.inscription_t.push(tmp);
    });
    data.int_dec_t.forEach((item, index) => {
      const tmp = { id: item.idintDecType, text: item.intDecT };
      this.int_dec_t.push(tmp);
    });
    data.l_form.forEach((item, index) => {
      const tmp = { id: item.idlocusForm, text: item.lForm };
      this.l_form.push(tmp);
    });
    data.religion_t.forEach((item, index) => {
      const tmp = { id: item.idreligionsList, text: item.religionT };
      this.religion_t.push(tmp);
    });
    data.rite_t.forEach((item, index) => {
      const tmp = { id: item.idriteList, text: item.riteT };
      this.rite_t.push(tmp);
    });
    data.t_form.forEach((item, index) => {
      const tmp = { id: item.idformsList, text: item.tForm };
      this.t_form.push(tmp);
    });

    this.hiden = true;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  startUpload(event: FileList) {
    const file = event.item(0);

    if (file.type.split('/')[0] !== 'image') {
      this.flashMessages.show('unsupported file type :( ', {
        cssClass: 'alert-danger',
        timeout: 5000
      });
      return;
    }

    const path = `tombs/${new Date().getTime()}_${file.name}`;

    this.newRecord.pic = path;

    this.task = this.storage.upload(path, file);

    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();

    this.snapshot
      .pipe(
        finalize(
          () => (this.downloadURL = this.storage.ref(path).getDownloadURL())
        )
      )
      .subscribe();
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  onSubmitClick() {
    const prepareArrays = async current => {
      await Object.keys(current).forEach(key => this.newRecord[key] = current[key].split(', '));
    };

    prepareArrays(this.current);

    const newTomb = {
      invNo: this.newRecord.id,
      catNo: this.newRecord.catNo,
      tombName: this.newRecord.tombName,
      siteName: this.newRecord.siteName,
      country: this.newRecord.country,
      wgsLat: this.newRecord.wgsLat,
      wgsLon: this.newRecord.wgsLon,
      nigLat: this.newRecord.nigLat,
      nigLon: this.newRecord.nigLon,
      oigLat: this.newRecord.oigLat,
      oigLon: this.newRecord.oigLon,
      remarks: this.newRecord.remarks,
      pic: this.newRecord.pic,
      capacity: this.newRecord.capacity,
      intermQ: this.newRecord.intermQ,
      chambersQ: this.newRecord.chambersQ,
      dating: this.newRecord.dating,
      tpq: this.newRecord.tpq,
      taq: this.newRecord.taq,
      tombReligion: this.newRecord.religion_t,
      tombEthnos: this.newRecord.ethnos_t,
      tombRite: this.newRecord.rite_t,
      tombIntDec: this.newRecord.int_dec_t,
      tombInscription: this.newRecord.inscription_t,
      tombFurnishing: this.newRecord.furnishing_t ? this.newRecord.furnishing_t : [0],
      tombContext: this.newRecord.context_t,
      tombEquipment: this.newRecord.equipment_t,
      tombConstruction: this.newRecord.construction_t,
      tombFasade: this.newRecord.fasade_t,
      tombLocus: this.newRecord.l_form,
      tombForm: this.newRecord.t_form,
      tombBib: this.newRecord.ref
    };

    console.log(newTomb);

    const dataSantize = data =>
      (data = Object.keys(data).map(item => (data[item] ? data[item] : [])));

    dataSantize(newTomb);

    if (!this.validation.tombValidator(newTomb)) {
      this.flashMessages.show('Fill out all the required fields!', {
        cssClass: 'alert-danger',
        timeout: 1000
      });
      return false;
    }

    this.tombsService.addTomb(newTomb).subscribe(data => {
      if (data.name) {
        this.flashMessages.show(
          `Adding to database filed: ${
            data.original.sqlMessage
          }, \n check your data and try again`,
          {
            cssClass: 'alert-danger',
            timeout: 3000
          }
        );
      } else {
        this.flashMessages.show('Success!', {
          cssClass: 'alert-success',
          timeout: 1000
        });
        this.current = {};
        this.newRecord = {};
        this.getID();
        localStorage.removeItem('browse-tombs');
      }
    });
  }

  changed(data: { value: string[] }, key) {
    this.current[key] =
      typeof data.value === 'object' ? data.value.join(', ') : data.value;
  }
}

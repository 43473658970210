import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TombsService {

  accessToken: any;
  headers: any;

  constructor(
    private http: Http,
    private auth: AuthService
    ) { }

  setHeaders() {
    this.auth.getToken().then(data => {
      this.accessToken = String(data);
      this.headers = new Headers();
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Authorization', `Bearer ${this.accessToken}`);
    });
  }

  getSelectors() {
    this.setHeaders();
    return this.http
      .get('/api/tombs/selectors', { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getID() {
    this.setHeaders();
    return this.http
      .get('/api/tombs/free-id', { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getTombsList() {
    this.setHeaders();
    return this.http
      .get('/api/lists/tombs', { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getShortList() {
    this.setHeaders();
    return this.http
      .get('/api/lists/short/tombs', { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getTombDetails(id) {
    this.setHeaders();
    return this.http
      .get(`/api/tombs/details/${id}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  addTomb(newTomb) {
    this.setHeaders();
    return this.http
      .post('api/tombs/add', newTomb, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  editTomb(editedTomb, id) {
    this.setHeaders();
    return this.http
      .put(`api/tombs/edit/${id}`, editedTomb, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  deleteTomb(id) {
    this.setHeaders();
    return this.http
      .delete(`/api/tombs/delete/${id}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  uppdateSearchTable() {
    this.setHeaders();
    return this.http
      .get('/api/tombs/search-uppdate', {headers: this.headers})
      .pipe(map(res => res.json()));
  }
}

import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { pipe } from '@angular/core/src/render3/pipe';

@Injectable({
  providedIn: 'root'
})
export class DataDumpService {
  accessToken: any;
  headers: any;

  constructor(private http: Http, private auth: AuthService) {}

  setHeaders() {
    this.auth.getToken().then(data => {
      this.accessToken = String(data);
      this.headers = new Headers();
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Authorization', `Bearer ${this.accessToken}`);
    });
  }

  dumpTombs() {
    this.setHeaders();
    return this.http
      .get('api/tombs/data-dump', { headers: this.headers })
      .pipe(map(data => data.json()));
  }

  dumpDoors() {
    this.setHeaders();
    return this.http
      .get('api/doors/data-dump', { headers: this.headers })
      .pipe(map(data => data.json()));
  }
}

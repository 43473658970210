import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LyticsService {
  accessToken: any;
  headers: any;

  constructor(private http: Http, private auth: AuthService) {}

  setHeaders() {
    this.auth.getToken().then(data => {
      this.accessToken = String(data);
      this.headers = new Headers();
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Authorization', `Bearer ${this.accessToken}`);
    });
  }

  getSimpleOcc(col, exc) {
    this.setHeaders();
    if (!this.headers === undefined) {
      this.setHeaders();
    }
    return this.http
      .get(`/api/lytics/occ/${col}/${exc}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getClauseValues(col) {
    this.setHeaders();
    if (!this.headers === undefined) {
      this.setHeaders();
    }
    return this.http
      .post(
        `/api/lytics/clause-values`,
        { col: col },
        { headers: this.headers }
      )
      .pipe(map(res => res.json()));
  }

  getAdvOcc(queryParams) {
    this.setHeaders();
    return this.http
      .post(`/api/lytics/occ/adv`, queryParams, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getTimeSpan(queryParams) {
    this.setHeaders();
    return this.http
      .post(`/api/lytics/time-span`, queryParams, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getSingularTimeSpan(queryParams)  {
    this.setHeaders();
    return this.http
      .post(`/api/lytics/time-span-singular`, queryParams, { headers: this.headers })
      .pipe(map(res => res.json()));
  }
}

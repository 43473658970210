import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';

import { SearchService } from '../../services/search.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-search-doors',
  templateUrl: './search-doors.component.html',
  styleUrls: ['./search-doors.component.css']
})
export class SearchDoorsComponent implements OnInit {

  doorsArr: Array<any>;
  doorQuery: String;

  constructor(
    private search: SearchService,
    private validation: ValidationService,
    private flashMsg: FlashMessagesService
    ) { }

  ngOnInit() {
    if (localStorage.getItem('doorsArr')) {
      this.doorsArr = JSON.parse(localStorage.getItem('doorsArr'));
    }
  }

  setLocalArr(arr) {
    localStorage.removeItem('tomb');
    localStorage.removeItem('door');
    const ids = arr.map(record => String(record.iddoors_main));
    localStorage.setItem('currentList', JSON.stringify(ids));
  }

  onDoorSubmit(query) {
    if (!this.validation.queryValidator(query)) {
      this.flashMsg.show('Najpierw wprowadź zapytanie', {
        cssClass: 'alert-danger',
        timeout: 5000
      });
      return false;
    }
    this.flashMsg.show('Trwa wyszukiwanie', {
      cssClass: 'alert-info',
      timeout: 2000
    });
    this.doorsArr = null;
    localStorage.removeItem('tombsArr');
    this.search.searchDoors(query).subscribe(data => {
      if (data[0].length) {
        this.doorsArr = data[0];
        this.setLocalArr(this.doorsArr);
        localStorage.setItem('doorsArr', JSON.stringify(this.doorsArr));
      } else {
        this.flashMsg.show('Nie znaleziono rekordów spełniających podane warunki.', {
          cssClass: 'alert-danger',
          timeout: 3000
        });
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authState: any = null;
  token: String;

  constructor(private firebaseAuth: AngularFireAuth, private router: Router) {
    this.firebaseAuth.auth.setPersistence(firebase.auth.Auth.Persistence.NONE);
    this.firebaseAuth.authState // .user
      .subscribe(user => {
        this.authState = user;
        localStorage.setItem('user', JSON.stringify(this.authState));
      });
  }

  authenticateUser(user) {
    return this.firebaseAuth.auth
      .signInWithEmailAndPassword(user.email, user.password)
      .then(currentUser => (this.authState = currentUser))
      .catch(err => err);
  }

  logout() {
    this.firebaseAuth.auth
      .signOut()
      .then(() => localStorage.clear())
      .then(() => this.router.navigate(['/logout']));
  }

  isLoggedIn() {
    if (this.authState && this.firebaseAuth.authState) {
      return true;
    } else {
      localStorage.clear();
      return false;
    }
  }

  resetPasswd(passwd) {
    return this.firebaseAuth.auth
      .sendPasswordResetEmail(passwd)
      .then(data => data)
      .catch(err => err);
  }

  getToken() {
    return this.firebaseAuth.auth.currentUser
      .getIdToken();
  }

  loadToken() {
    this.getToken();
    return this.token;
  }
}

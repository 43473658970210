import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';

import { DoorsService } from '../../services/doors.service';
import { TombsService } from '../../services/tombs.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private router: Router,
    private flashMsg: FlashMessagesService,
    private doors: DoorsService,
    private tombs: TombsService
  ) {}

  ngOnInit() {}

  onLogoutClick() {
    this.auth.logout();
    this.router.navigate(['login']);
    return false;
  }

  onUppdateTombsClick() {
    this.tombs.uppdateSearchTable().subscribe(data => {
      if (data === '1') {
        this.flashMsg.show('Success!', {
          cssClass: 'alert-success',
          timeout: 1000
        });
      } else {
        this.flashMsg.show('Something went wrong, try again later.', {
          cssClass: 'alert-danger',
          timeout: 1000
        });
      }
    });
  }

  onUppdateDoorsClick() {
    this.doors.uppdateSearchTable().subscribe(data => {
      if (data === '1') {
        this.flashMsg.show('Success!', {
          cssClass: 'alert-success',
          timeout: 1000
        });
      } else {
        this.flashMsg.show('Something went wrong, try again later.', {
          cssClass: 'alert-danger',
          timeout: 1000
        });
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.setDate();
  }

  // Set date in footer
  setDate() {
    const footerDate = <HTMLSpanElement>document.getElementById('data');
    const date = new Date().getFullYear();
    const dateOutput = date.toString() === '2018' ? '2018' : `2017 - ${date}`;
    footerDate.innerHTML = dateOutput;
  }

}

import { Component, OnInit } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/storage';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Select2OptionData } from 'ng2-select2';
import { FlashMessagesService } from 'angular2-flash-messages';

import { DoorsService } from '../../services/doors.service';

@Component({
  selector: 'app-add-doors',
  templateUrl: './add-doors.component.html',
  styleUrls: ['./add-doors.component.css']
})
export class AddDoorsComponent implements OnInit {
  selectors: Array<Select2OptionData> = [];
  value: string[];
  newRecord: any = {};
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: any;
  isHovering: boolean;

  constructor(
    private flashMessages: FlashMessagesService,
    private doorsService: DoorsService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit() {
    this.getID();
  }

  getID() {
    this.doorsService.getID().subscribe(data => (this.newRecord.id = data));
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  startUpload(event: FileList) {
    const file = event.item(0);

    if (file.type.split('/')[0] !== 'image') {
      this.flashMessages.show('unsupported file type :( ', {
        cssClass: 'alert-danger',
        timeout: 5000
      });
      return;
    }

    const path = `doors/${new Date().getTime()}_${file.name}`;

    this.newRecord.pic = path;

    this.task = this.storage.upload(path, file);

    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();

    this.snapshot
      .pipe(
        finalize(
          () => (this.downloadURL = this.storage.ref(path).getDownloadURL())
        )
      )
      .subscribe();
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  onSubmitClick() {
    const newDoor = {
      invNo: this.newRecord.id,
      catNo: this.newRecord.catNo,
      mInvNo: this.newRecord.mInvNo,
      storagePlace: this.newRecord.storagePlace,
      origin: this.newRecord.origin,
      region: this.newRecord.region,
      coordLat: this.newRecord.coordLat,
      coordLon: this.newRecord.coordLon,
      context: this.newRecord.context,
      material: this.newRecord.material,
      width: this.newRecord.width,
      height: this.newRecord.height,
      thickness: this.newRecord.thickness,
      type: this.newRecord.type,
      preservation: this.newRecord.preservation,
      decoration: this.newRecord.decoration,
      dating: this.newRecord.dating,
      pic: this.newRecord.pic,
      structuralElements: this.newRecord.structuralElements,
      doorBib: this.newRecord.doorBib
    };

    console.log(newDoor);

    if (!newDoor.invNo || !newDoor.catNo || !newDoor.doorBib) {
      this.flashMessages.show('Fill out all the required fields!', {
        cssClass: 'alert-danger',
        timeout: 1000
      });
      return false;
    }

    this.doorsService.addDoors(newDoor).subscribe(data => {
      if (data.errors) {
        this.flashMessages.show(
          `Adding to database filed: ${
            data.errors[0].message
          }, \n check your data and try again`,
          {
            cssClass: 'alert-danger',
            timeout: 3000
          }
        );
      } else {
        this.flashMessages.show('Success!', {
          cssClass: 'alert-success',
          timeout: 1000
        });
        this.newRecord = {};
        this.getID();
        localStorage.removeItem('browse-doors');
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email: String;
  password: String;
  resetEmail: String;

  constructor(
    private auth: AuthService,
    private router: Router,
    private flashMessages: FlashMessagesService) { }

  ngOnInit() {
    this.checkUser();
  }

  onLoginSubmit() {
    const user = {
      email: this.email,
      password: this.password
    };

    this.auth.authenticateUser(user)
      .then((data) => {
        if (data.user) {
          this.flashMessages.show('Zalogowano', {cssClass: 'alert-success', timeout: 5000});
          this.router.navigate(['/']);
        } else {
          console.log('bad passwd');
          this.flashMessages.show('Błędny e-mail lub hasło, spróbuj ponownie.', {cssClass: 'alert-danger', timeout: 5000});
          this.router.navigate(['login']);
        }
      })
      .catch(err => console.log(err));
  }

  onResetSubmit() {
    this.auth.resetPasswd(this.resetEmail)
      .then(data => {
        if (data) {
          const msg = data.message ? 'Nie zarejestrowano użytkownika na podany adres email.' : 'Coś poszło nie tak, spróbuj ponownie.';
          this.flashMessages.show(msg, {cssClass: 'alert-danger', timeout: 5000});
        } else {
          this.toggleReset();
          this.flashMessages.show('Hasło zresetowane pomyślnie, sprawdź email w poszukiwaniu wiadomości z linkeim resetującym',
            {cssClass: 'alert-success', timeout: 5000});
        }
      })
      .catch(err => console.log('err', err));
  }

  toggleReset() {
    const loginPanel = <HTMLElement>document.getElementById('login-form');
    const resetPanel = <HTMLElement>document.getElementById('reset-form');

    if (loginPanel.classList.contains('active')) {
      resetPanel.classList.add('active');
      loginPanel.classList.remove('active');
    } else {
      resetPanel.classList.remove('active');
      loginPanel.classList.add('active');
    }
  }

  checkUser() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

}

import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  accessToken: any;
  headers: any;

  constructor(private http: Http, private auth: AuthService) {}

  setHeaders() {
    this.auth.getToken().then(data => {
      this.accessToken = String(data);
      this.headers = new Headers();
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Authorization', `Bearer ${this.accessToken}`);
    });
  }

  searchTombs(query) {
    this.setHeaders();
    return this.http
      .post('/api/search/tombs', {query: query}, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  searchDoors(query) {
    this.setHeaders();
    return this.http
      .post('/api/search/doors', {query: query}, { headers: this.headers })
      .pipe(map(res => res.json()));
  }
}

import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';

import { TombsService } from '../../services/tombs.service';
import { LyticsService } from '../../services/lytics.service';

@Component({
  selector: 'app-lytics',
  templateUrl: './lytics.component.html',
  styleUrls: ['./lytics.component.css']
})
export class LyticsComponent implements OnInit {
  simpleKeys: Array<string> = [
    'RITE',
    'INT_DEC',
    'INSCRIPTION',
    'FURNISHING',
    'CONTEXT',
    'EQUIPMENT',
    'CONSTRUCTION',
    'FASADE',
    'LOCUS',
    'FORM'
  ];
  simpleColQuery: String;
  advClauseCols: Array<string> = [
    'tomb_name',
    'site_name',
    'country',
    'capacity',
    'interm_q',
    'chambers_q'
  ];
  advTimeSpanCols: Array<string> = [
    'RITE',
    'INT_DEC',
    'INSCRIPTION',
    'FURNISHING',
    'CONTEXT',
    'EQUIPMENT',
    'CONSTRUCTION',
    'FASADE',
    'LOCUS',
    'FORM',
    '*',
    'country',
    'capacity',
    'chambers_q'
  ];
  TimeSpan1ValCols: Array<string> = [
    'RITE',
    'INT_DEC',
    'INSCRIPTION',
    'FURNISHING',
    'CONTEXT',
    'EQUIPMENT',
    'CONSTRUCTION',
    'FASADE',
    'LOCUS',
    'FORM'
  ];
  advClauseVals: Array<string> = [];
  advClauseCol: String;
  advClauseVal: String;
  advTimeSpanVals: Array<string> = [];
  advTimeSpanCol: String;
  advTimeSpanVal: String;
  TimeSpan1ValCol: String;
  TimeSpanOneVal: String;
  TimeSpanOneVals: any = [];
  advanceColQuery: String;
  excSimple: Boolean = false;
  excAdv: Boolean = false;
  excTimeSpan: Boolean = false;
  excTimeSpanOne: Boolean = false;
  short: Boolean = false;
  shortOne: Boolean = false;
  repeted: Boolean = false;
  link: Boolean = false;
  linkOne: Boolean = false;

  constructor(
    private tombs: TombsService,
    private flashMessages: FlashMessagesService,
    private lytics: LyticsService
  ) {}

  ngOnInit() {
    this.onAdvClauseChange('');
  }

  onSimpleSubmit(query) {
    const sQuery = query;
    this.flashMessages.show('Generowanie pliku.', {
      cssClass: 'alert-info',
      timeout: 10000
    });
    this.lytics.getSimpleOcc(sQuery, this.excSimple).subscribe(data => {
      if (data === 'File created') {
        this.flashMessages.show('Plik utworzony', {
          cssClass: 'alert-success',
          timeout: 5000
        });
        window.open('out.xlsx');
        this.repeted = false;
      } else if (data === 'Unauthorized' && !this.repeted) {
        this.repeted = true;
        this.onSimpleSubmit(sQuery);
      } else {
        this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      }
    });
  }

  onAdvClauseChange(col) {
    const qCol = col;
    this.lytics.getClauseValues(qCol).subscribe(data => {
      if (data.statys === 403 && !this.repeted) {
        this.repeted = true;
        this.onAdvClauseChange(qCol);
      } else {
        this.advClauseVals = data.map(item => Object.values(item));
        this.repeted = false;
      }
    });
  }

  onAdvTimeSpanChange(col) {
    const qCol = col;
    const full = ['%'];
    if (qCol === '*') {
      this.advTimeSpanVals = full;
      return;
    }
    this.lytics.getClauseValues(qCol).subscribe(data => {
      if (data.statys === 403 && !this.repeted) {
        this.repeted = true;
        this.onAdvClauseChange(qCol);
      } else {
        this.advTimeSpanVals = data.map(item => Object.values(item));
        this.repeted = false;
      }
    });
  }

  onAdvanceSubmit() {
    const obj = {
      col: this.advanceColQuery,
      exc: this.excAdv,
      qCol: this.advClauseCol,
      qVal: this.advClauseVal
    };
    if (!obj.col || !obj.qCol || !obj.qVal) {
      this.flashMessages.show('Wypełnij wsztystkie wymagane pola?', {
        cssClass: 'alert-danger',
        timeout: 5000
      });
      return;
    }
    this.flashMessages.show('Generowanie pliku.', {
      cssClass: 'alert-info',
      timeout: 10000
    });
    this.lytics.getAdvOcc(obj).subscribe(data => {
      if (data === 'File created') {
        this.flashMessages.show('Plik utworzony', {
          cssClass: 'alert-success',
          timeout: 5000
        });
        window.open('out.xlsx');
        this.repeted = false;
      } else if (data === 'Unauthorized' && !this.repeted) {
        this.repeted = true;
        this.onAdvanceSubmit();
      } else {
        this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      }
    });
  }

  onTimeSpanSubmit() {
    this.link = false;
    const obj = {
      column: this.advTimeSpanCol,
      term: this.advTimeSpanVal,
      exc: this.excTimeSpan,
      short: this.short
    };
    if (!obj.column || !obj.term) {
      this.flashMessages.show('Wypełnij wsztystkie wymagane pola?', {
        cssClass: 'alert-danger',
        timeout: 5000
      });
      return;
    }
    this.flashMessages.show('Generowanie pliku.', {
      cssClass: 'alert-info',
      timeout: 10000
    });
    this.lytics.getTimeSpan(obj).subscribe(data => {
      if (data === 'File created') {
        this.link = true;
        this.repeted = false;
        this.flashMessages.show('Plik utworzony', {
          cssClass: 'alert-success',
          timeout: 5000
        });
        window.open('out.xlsx');
      } else if (data === 'Unauthorized' && !this.repeted) {
        this.repeted = true;
        this.onAdvanceSubmit();
      } else {
        this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      }
    });
  }

  onTimeSpanOneChange(col) {
    if (col === 'RITE') {
      this.TimeSpanOneVals = [
        'not set',
        'cremation',
        'inhumation',
        'reposition',
        'unknown'
      ];
    } else if (col === 'INT_DEC') {
      this.TimeSpanOneVals = [
        'not set',
        'paintings',
        'mosaic',
        'relief',
        'no internal decoration',
        'nodata',
        'pintings no data',
        'relief no data'
      ];
    } else if (col === 'INSCRIPTION') {
      this.TimeSpanOneVals = [
        'not set',
        'no inscription',
        'unknown language',
        'no data',
        'aramaic',
        'edessean',
        'greek',
        'hebrew',
        'latin',
        'palmyrenian',
        'phoenician',
        'nabatean',
        'safatic',
        'syriac'
      ];
    }
    if (col === 'FURNISHING') {
      this.TimeSpanOneVals = [
        'not set',
        'clay coffin',
        'lead coffin',
        'wooden coffin',
        'ossuary',
        'sarcophagus',
        'no data'
      ];
    } else if (col === 'CONTEXT') {
      this.TimeSpanOneVals = [
        'not set',
        'monsatery',
        'town church',
        'town necropolis',
        'village church',
        'village necropolis',
        'no data'
      ];
    } else if (col === 'EQUIPMENT') {
      this.TimeSpanOneVals = [
        'not set',
        'basket cordage',
        'bone object',
        'coin',
        'glass vessel',
        'jewelery',
        'metal',
        'oil lamp',
        'pottery',
        'spindle whorl/loom weight',
        'terracota fig.',
        'stone object',
        'textile',
        'wood',
        'leather',
        'animal bones',
        'plants',
        'cultic instalation',
        'shell',
        'other'
      ];
    } else if (col === 'CONSTRUCTION') {
      this.TimeSpanOneVals = ['not set', 'rock cut', 'built', 'unclear'];
    } else if (col === 'FASADE') {
      this.TimeSpanOneVals = ['with_decoration', 'undecorated', 'unknown'];
    } else if (col === 'LOCUS') {
      this.TimeSpanOneVals = [
        'not set',
        'arcosolia',
        'bench',
        'loculi',
        'pit',
        'repository',
        'through',
        'unclear'
      ];
    } else if (col === 'FORM') {
      this.TimeSpanOneVals = [
        'not set',
        'cave',
        'mausoleum',
        'pit chamber',
        'rock cut chamber',
        'built hypogeum',
        'tower',
        'tumulus',
        'unclear form'
      ];
    }
  }

  onTimeSpanOneSubmit() {
    this.linkOne = false;
    const obj = {
      column: this.TimeSpan1ValCol,
      term: `%${this.TimeSpanOneVal}%`,
      exc: this.excTimeSpanOne,
      short: this.shortOne
    };
    if (!obj.column || !obj.term) {
      this.flashMessages.show('Wypełnij wsztystkie wymagane pola?', {
        cssClass: 'alert-danger',
        timeout: 5000
      });
      return;
    }
    this.flashMessages.show('Generowanie pliku.', {
      cssClass: 'alert-info',
      timeout: 3000
    });
    this.lytics.getSingularTimeSpan(obj).subscribe(data => {
      if (data === 'File created') {
        this.linkOne = true;
        this.repeted = false;
        this.flashMessages.show('Plik utworzony', {
          cssClass: 'alert-success',
          timeout: 5000
        });
        window.open('out.xlsx');
      } else if (data === 'Unauthorized' && !this.repeted) {
        this.repeted = true;
        this.onAdvanceSubmit();
      } else {
        this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      }
    });
  }
}

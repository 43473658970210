import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DoorsService {
  accessToken: any;
  headers: any;

  constructor(private http: Http, private auth: AuthService) {}

  setHeaders() {
    this.auth.getToken()
      .then(data => {
        this.accessToken = String(data);
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Authorization', `Bearer ${this.accessToken}`);
      });
  }

  getID() {
    this.setHeaders();
    return this.http
      .get('/api/doors/free-id', { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getSelectors() {
    this.setHeaders();
    return this.http
      .get('/api/doors/selectors', { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getDoorsList() {
    this.setHeaders();
    return this.http
      .get('/api/lists/doors', { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getShortList() {
    this.setHeaders();
    return this.http
      .get('/api/lists/short/doors', { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  getDoorDetails(id) {
    this.setHeaders();
    return this.http
      .get(`/api/doors/details/${id}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  addDoors(newDoor) {
    this.setHeaders();
    return this.http
      .post('api/doors/add', newDoor, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  editDoor(editedDoor, id) {
    this.setHeaders();
    return this.http
      .put(`api/doors/edit/${id}`, editedDoor, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  deleteDoor(id) {
    this.setHeaders();
    return this.http
      .delete(`/api/doors/delete/${id}`, { headers: this.headers })
      .pipe(map(res => res.json()));
  }

  uppdateSearchTable() {
    this.setHeaders();
    return this.http
      .get('/api/doors/search-uppdate', {headers: this.headers})
      .pipe(map(res => res.json()));
  }
}

import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';

import { SearchService } from '../../services/search.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-search-tombs',
  templateUrl: './search-tombs.component.html',
  styleUrls: ['./search-tombs.component.css']
})
export class SearchTombsComponent implements OnInit {

  tombsArr: Array<any>;
  tombQuery: String;

  constructor(
    private search: SearchService,
    private validation: ValidationService,
    private flashMsg: FlashMessagesService
    ) { }

  ngOnInit() {
    if (localStorage.getItem('tombsArr')) {
      this.tombsArr = JSON.parse(localStorage.getItem('tombsArr'));
    }
  }

  setLocalArr(arr) {
    localStorage.removeItem('tomb');
    localStorage.removeItem('door');
    const ids = arr.map(record => String(record.id));
    localStorage.setItem('currentList', JSON.stringify(ids));
  }

  onTombsSubmit(query) {
    if (!this.validation.queryValidator(query)) {
      this.flashMsg.show('Najpierw wprowadź zapytanie', {
        cssClass: 'alert-danger',
        timeout: 5000
      });
      return false;
    }
    this.flashMsg.show('Trwa wyszukiwanie', {
      cssClass: 'alert-info',
      timeout: 2000
    });
    this.tombsArr = null;
    localStorage.removeItem('tombsArr');
    this.search.searchTombs(query).subscribe(data => {
      if (data[0].length) {
        this.tombsArr = data[0];
        this.setLocalArr(this.tombsArr);
        localStorage.setItem('tombsArr', JSON.stringify(this.tombsArr));
      } else {
        this.flashMsg.show('Nie znaleziono rekordów spełniających podane warunki.', {
          cssClass: 'alert-danger',
          timeout: 3000
        });
      }
    });
  }
}

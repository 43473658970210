import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  constructor() {}

  tombValidator(input) {
    const list = [
      'catNo',
      'country',
      'dating',
      'invNo',
      'siteName',
      'taq',
      'tombBib',
      'tombConstruction',
      'tombContext',
      'tombEquipment',
      'tombEthnos',
      'tombFasade',
      'tombForm',
      'tombInscription',
      'tombIntDec',
      'tombLocus',
      'tombName',
      'tombReligion',
      'tombRite',
      'tpq',
      'wgsLat',
      'wgsLon'
    ];

    let flag = true;

    list.forEach(key => {
      if (!input[key]) {
        console.log(key);
        flag = false;
      }
    });

    if (flag) {
      return true;
    } else {
      return false;
    }
  }

  queryValidator(query) {
    if (query && typeof(query) === 'string') {
      return true;
    } else {
      return false;
    }
  }
}

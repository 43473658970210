import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

import { TombsService } from '../../services/tombs.service';

@Component({
  selector: 'app-tomb-details',
  templateUrl: './tomb-details.component.html',
  styleUrls: ['./tomb-details.component.css']
})
export class TombDetailsComponent implements OnInit {
  id: string;
  record: any;
  selectors: any;
  render: Boolean = false;
  path: any;
  isDisabledBack: Boolean = false;
  isDisabledForward: Boolean = false;
  ls: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private flashMessages: FlashMessagesService,
    private storage: AngularFireStorage,
    private tombsService: TombsService,
  ) {  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getSelectors().then(() => this.getDetails(this.id));
      if (localStorage.getItem('tomb')) {
        this.ls = JSON.parse(localStorage.getItem('tomb'));
      } else {
        this.ls = JSON.parse(localStorage.getItem('currentList'));
      }
      this.setDisabled();
      this.flashMessages.show('Loading...', {
        cssClass: 'alert-info',
        timeout: 1000
      });
    });
  }

  setDisabled() {
    if (this.ls.indexOf(this.id) === 0) {
      this.isDisabledBack = true;
    } else {
      this.isDisabledBack = false;
    }
    if (this.ls.indexOf(this.id) === this.ls.length - 1) {
      this.isDisabledForward = true;
    } else {
      this.isDisabledForward = false;
    }
  }

  onGoBack() {
    this.router.navigate(['/details-tomb', this.ls[this.ls.indexOf(this.id) - 1]]);
  }
  onGoForward() {
    this.router.navigate(['/details-tomb', this.ls[this.ls.indexOf(this.id) + 1]]);
  }

  getSelectors() {
    return new Promise(async (reslove, reject) => {
      this.tombsService.getSelectors().subscribe(data => {
        this.selectors = data;
      });

      await reslove();
    });
  }

  getDetails(id) {
    this.tombsService.getTombDetails(id)
    .pipe(finalize(() => {
      this.path = this.record.pic ? this.storage.ref(this.record.pic).getDownloadURL() : '';
    }))
    .subscribe(async data => {
      this.record = data;
       await this.prepareData();
    });
  }

  prepareData() {
    let tmp: any;

    let tFormTmp: Array<any> = [];
    JSON.parse(this.record.tForm).forEach(element => {
      tmp = this.selectors.t_form.filter(
        selector => selector.idformsList === element
      );
      tFormTmp.push(tmp[0].tForm);
    });
    tFormTmp = tFormTmp.filter(item => item !== 'not set');
    this.record.tForm = tFormTmp;

    let locusTmp: Array<any> = [];
    JSON.parse(this.record.locus).forEach(element => {
      tmp = this.selectors.l_form.filter(
        selector => selector.idlocusForm === element
      );
      locusTmp.push(tmp[0].lForm);
    });
    locusTmp = locusTmp.filter(item => item !== 'not set');
    this.record.locus = locusTmp;

    let constructionTmp: Array<any> = [];
    JSON.parse(this.record.construction).forEach(element => {
      tmp = this.selectors.construction_t.filter(
        selector => selector.idconstructionType === element
      );
      constructionTmp.push(tmp[0].constructionT);
    });
    constructionTmp = constructionTmp.filter(item => item !== 'not set');
    this.record.construction = constructionTmp;


    let religionTmp: Array<any> = [];
    JSON.parse(this.record.religion).forEach(element => {
      tmp = this.selectors.religion_t.filter(
        selector => selector.idreligionsList === element
      );
      religionTmp.push(tmp[0].religionT);
    });
    religionTmp = religionTmp.filter(item => item !== 'not set');
    this.record.religion = religionTmp;

    let ethnosTmp: Array<any> = [];
    JSON.parse(this.record.ethnos).forEach(element => {
      tmp = this.selectors.ethnos_t.filter(
        selector => selector.idethnosList === element
      );
      ethnosTmp.push(tmp[0].ethnosT);
    });
    ethnosTmp = ethnosTmp.filter(item => item !== 'not set');
    this.record.ethnos = ethnosTmp;

    let riteTmp: Array<any> = [];
    JSON.parse(this.record.rite).forEach(element => {
      tmp = this.selectors.rite_t.filter(
        selector => selector.idriteList === element
      );
      riteTmp.push(tmp[0].riteT);
    });
    riteTmp = riteTmp.filter(item => item !== 'not set');
    this.record.rite = riteTmp;

    tmp = this.selectors.fasade_t.filter(
      selector => selector.idfasadeType === this.record.fasadeTypeIdfasadeType
    );
    this.record.fasadeTypeIdfasadeType = tmp[0].fasadeT;

    let intDecTmp: Array<any> = [];
    JSON.parse(this.record.intDec).forEach(element => {
      tmp = this.selectors.int_dec_t.filter(
        selector => selector.idintDecType === element
      );
      intDecTmp.push(tmp[0].intDecT);
    });
    intDecTmp = intDecTmp.filter(item => item !== 'not set');
    this.record.intDec = intDecTmp;

    let inscriptionTmp: Array<any> = [];
    JSON.parse(this.record.inscription).forEach(element => {
      tmp = this.selectors.inscription_t.filter(
        selector => selector.idinscriptionType === element
      );
      inscriptionTmp.push(tmp[0].inscriptionT);
    });
    inscriptionTmp = inscriptionTmp.filter(item => item !== 'not set');
    this.record.inscription = inscriptionTmp;

    let furnishingTmp: Array<any> = [];
    JSON.parse(this.record.furnishing).forEach(element => {
      tmp = this.selectors.furnishing_t.filter(
        selector => selector.idfurnishingType === element
      );
      furnishingTmp.push(tmp[0].furnishingT);
    });
    furnishingTmp = furnishingTmp.filter(item => item !== 'not set');
    this.record.furnishing = furnishingTmp;

    let contextTmp: Array<any> = [];
    JSON.parse(this.record.context).forEach(element => {
      tmp = this.selectors.context_t.filter(
        selector => selector.idcontextType === element
      );
      contextTmp.push(tmp[0].contextT);
    });
    contextTmp = contextTmp.filter(item => item !== 'not set');
    this.record.context = contextTmp;

    let equipmentTmp: Array<any> = [];
    JSON.parse(this.record.equipemnt).forEach(element => {
      tmp = this.selectors.equipment_t.filter(
        selector => selector.idequipmentType === element
      );
      equipmentTmp.push(tmp[0].equipmentT);
    });
    equipmentTmp = equipmentTmp.filter(item => item !== 'not set');
    this.record.equipemnt = equipmentTmp;

    this.togglRender();
  }

  togglRender() {
    this.render = true;
  }

  onDeleteClick(id) {
    this.tombsService.deleteTomb(id).subscribe(data => {
      if (data === 1) {
        this.flashMessages.show('Usunięto poprawnie', {
          cssClass: 'alert-success',
          timeout: 5000
        });
        this.router.navigate(['/browse-tombs']);
      } else {
        this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {
          cssClass: 'alert-danger',
          timeout: 5000
        });
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';

import { DoorsService } from '../../services/doors.service';

@Component({
  selector: 'app-browse-doors',
  templateUrl: './browse-doors.component.html',
  styleUrls: ['./browse-doors.component.css']
})
export class BrowseDoorsComponent implements OnInit {

  records: Array<any>;

  constructor(
    private doorService: DoorsService,
    private flashMessages: FlashMessagesService
  ) { }

  ngOnInit() {
    if (localStorage.getItem('browse-doors')) {
      this. records = JSON.parse(localStorage.getItem('browse-doors'));
    } else {
      this.getList();
    }
  }

  getList() {
    this.doorService.getDoorsList()
      .subscribe(data => {
        this.records = data;
        this.setLocalArr();
        localStorage.setItem('browse-doors', JSON.stringify(this.records));
      });
  }

  setLocalArr() {
    localStorage.removeItem('tomb');
    localStorage.removeItem('door');
    const ids = this.records.map(record => String(record.iddoorsMain));
    localStorage.setItem('currentList', JSON.stringify(ids));
  }

  onDeleteClick(id) {
    this.doorService.deleteDoor(id)
      .subscribe(data => {
        if (data === 1) {
          this.flashMessages.show('Usunięto poprawnie', {cssClass: 'alert-success', timeout: 5000});
          this.getList();
        } else {
          this.flashMessages.show('Coś poszło nie tak, spróbuj ponownie.', {cssClass: 'alert-danger', timeout: 5000});
        }
      });
  }

}

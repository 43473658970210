import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Select2OptionData, Select2Module } from 'ng2-select2';


import { DoorsService } from '../../services/doors.service';

@Component({
  selector: 'app-edit-doors',
  templateUrl: './edit-doors.component.html',
  styleUrls: ['./edit-doors.component.css']
})
export class EditDoorsComponent implements OnInit {

  id: String;
  record: any;
  value: string[];
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: any;
  isHovering: boolean;
  isDisabledBack: Boolean = false;
  isDisabledForward: Boolean = false;
  ls: any;

  constructor(
    private flashMessages: FlashMessagesService,
    private router: Router,
    private route: ActivatedRoute,
    private doorsService: DoorsService,
    private storage: AngularFireStorage,
    private location: Location
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getDetails(this.id);
      if (localStorage.getItem('door')) {
        this.ls = JSON.parse(localStorage.getItem('door'));
      } else {
        this.ls = JSON.parse(localStorage.getItem('currentList'));
      }
      this.setDisabled();
      this.flashMessages.show('Loading...', {
        cssClass: 'alert-info',
        timeout: 1000
      });
    });
  }

  setDisabled() {
    if (this.ls.indexOf(this.id) === 0) {
      this.isDisabledBack = true;
    } else {
      this.isDisabledBack = false;
    }
    if (this.ls.indexOf(this.id) === this.ls.length - 1) {
      this.isDisabledForward = true;
    } else {
      this.isDisabledForward = false;
    }
  }

  onGoBack() {
    this.router.navigate(['/edit-door', this.ls[this.ls.indexOf(this.id) - 1]]);
  }
  onGoForward() {
    this.router.navigate(['/edit-door', this.ls[this.ls.indexOf(this.id) + 1]]);
  }

  getDetails(id) {
    this.doorsService.getDoorDetails(id)
      .pipe(finalize(() => {
        this.downloadURL = this.record.pic ? this.storage.ref(this.record.pic).getDownloadURL() : '';
      }))
      .subscribe(data => {
        this.record = data;
      });
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  startUpload(event: FileList) {
    const file = event.item(0);

    if (file.type.split('/')[0] !== 'image') {
      this.flashMessages.show('unsupported file type :( ', {
        cssClass: 'alert-danger',
        timeout: 5000
      });
      return;
    }

    const path = `doors/${new Date().getTime()}_${file.name}`;

    this.record.pic = path;

    this.task = this.storage.upload(path, file);

    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();

    this.snapshot
      .pipe(
        finalize(
          () => (this.downloadURL = this.storage.ref(path).getDownloadURL())
        )
      )
      .subscribe();
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  onSubmitClick() {
    const newDoor = {
      invNo: this.id,
      catNo: this.record.catNo,
      mInvNo: this.record.musInvNo,
      storagePlace: this.record.storagePlace,
      origin: this.record.originPlace,
      region: this.record.region,
      coordLat: this.record.coordLat,
      coordLon: this.record.coordLon,
      context: this.record.context,
      material: this.record.mat,
      width: this.record.width,
      height: this.record.height,
      thickness: this.record.thickness,
      type: this.record.type,
      preservation: this.record.preservation,
      decoration: this.record.decoration,
      dating: this.record.dating,
      pic: this.record.pic,
      structuralElements: this.record.structuralElements,
      doorBib: this.record.ref
    };
    if (!newDoor.invNo || !newDoor.catNo || !newDoor.doorBib) {
      this.flashMessages.show('Fill out all the required fields!', {
        cssClass: 'alert-danger',
        timeout: 1000
      });
      return false;
    }

    this.doorsService.editDoor(newDoor, this.id).subscribe(data => {
      if (data.errors) {
        this.flashMessages.show(
          `Adding to database filed: ${
            data.errors[0].message
          }, \n check your data and try again`,
          {
            cssClass: 'alert-danger',
            timeout: 3000
          }
        );
      } else {
        this.flashMessages.show('Success!', {
          cssClass: 'alert-success',
          timeout: 1000
        });
        localStorage.removeItem('browse-doors');
        this.router.navigate(['/browse-doors']);
      }
    });
  }

  goBack() {
    this.location.back();
  }
}
